var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableDataStatistics", {
    attrs: {
      columns: _vm.columns,
      getListFn: _vm.getListFn
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };